const EnumMotivoReagendamento = {
  AlterarClinicaColaborador: 'Colaborador solicitou alterar a clínica',
  AlterarDiaColaborador: 'Colaborador solicitou alterar o dia',
  AlterarCidadeColaborador: 'Colaborador solicitou alterar a cidade',
  AlterarPeriodoColaborador: 'Colaborador solicitou alterar o período',
  AlterarClinicaCliente: 'Cliente solicitou alterar a clínica',
  AlterarDiaCliente: 'Cliente solicitou alterar o dia',
  AlterarCidadeCliente: 'Cliente solicitou alterar a cidade',
  AlterarPeriodoCliente: 'Cliente solicitou alterar o período',
  NaoAtendidoErroPrestadorDuploVinculo: 'Colaborador não foi atendido por erro do prestador duplo vínculo',
  NaoAtendidoErroPrestadorSemAgendamento: 'Colaborador não foi atendido por erro do prestador alegar que não tem agendamento',
  NaoAtendidoPorFaltaPagamento: 'Colaborador não foi atendido por falta de pagamento',
  NaoAtendidoPorFaltaKit: 'Colaborador não foi atendido por falta de kit',
  Outros: 'Outros',
};
export default EnumMotivoReagendamento;
