<template>
    <div>
        <HeaderCard v-show="false" :loading="loading" tittle="Motivos de reagendamento" @onLoad="load" :filtroStatusEmAndamento="true" />
        <Chart type="bar" :data="stackedData" :options="stackedOptions" class="my-chart h-30rem" :plugins="plugins" />
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import EnumMotivoReagendamento from '../../../../enums/EnumMotivoReagendamento';

export default {
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        },
        ocultarInfosFaltaPagamentoEkit: {
            type: Boolean,
            default: false,
        },
        usersIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            statusIds: [],
            loading: false,
            enumMotivoReagendamento: EnumMotivoReagendamento,
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;
                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 45;
                        };
                    }
                }
            ]
        };
    },
    components: {
        HeaderCard
    },
    computed: {
        stackedData() {
            return {
                labels: this.dados.map((_d, index) => (index + 1).toString()),
                datasets: this.dados.map((d, index) => {
                    return {
                        label: `${index + 1} - ${this.enumMotivoReagendamento[d.motivo]}`,
                        data: this.dados.map((p) => {
                            if (p.motivo == d.motivo) {
                                return p.quantidade;
                            } else {
                                return null;
                            }
                        }),
                        porcentagem: this.dados.map((p) => {
                            if (p.motivo == d.motivo) {
                                return p.porcentagem;
                            } else {
                                return null;
                            }
                        }),
                        backgroundColor: d.cor,
                        skipNull: true,
                        intersect: true
                    };
                })
            };
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                interaction: {
                    mode: 'nearest'
                },
                plugins: {
                    legend: {
                        labels: {
                            fontColor: '#495057'
                        }
                    },
                    datalabels: {
                        formatter: (_value, context) => {
                            const porcentagem = Number(context.dataset.porcentagem[context.dataIndex]).toFixed(0);
                            const quantidade = context.dataset.data[context.dataIndex];
                            if (porcentagem && quantidade) return [`${porcentagem}%`, quantidade];
                        },
                        anchor: 'end',
                        align: 'top',
                        position: 'top',
                        color: 'black',
                        labels: {
                            value: {
                                color: 'black',
                                font: {
                                    size: 12
                                }
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: true,
                            drawBorder: false,
                            color: '#EBEDEF'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#EBEDEF',
                            drawBorder: false
                        }
                    }
                }
            };
        }
    },

    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            this.statusIds = params.statusIds;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/reagendamentos/por-clientes/motivos', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined,
                            statusIds: this.statusIds?.length ? this.statusIds : undefined,
                            ocultarInfosFaltaPagamentoEkit: this.ocultarInfosFaltaPagamentoEkit,
                            userIds: this.usersIds?.length ? this.usersIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>
