<template>
    <div class="w-full h-full">
        <HeaderCard :loading="loading" tittle="Quantidade por tipo de exame" @onLoad="load" :comFiltro="false" />
        <div class="w-full h-full flex justify-content-around">
            <div class="w-8 flex justify-content-center align-items-center">
                <Chart type="doughnut" :data="chartData" :options="chartOptions" class="w-20rem" />
            </div>
            <div class="w-3 flex flex-column pt-5 pl-">
                <div style="display: flex; flex-direction: column; margin: 0 0 20px 0">
                    <span class="text-6xl font-semibold text-800">{{ quantidadeTotal }}</span>
                    <span class="text-base font-medium text-700">Total de exames</span>
                </div>
                <div class="flex flex-column overflow-auto pt-5 pl-2">
                    <div class="scrollable-list">
                        <div v-for="(exame, index) in labels" :key="index" :style="index === labels.length - 1 && { margin: '0 0 50px 0' }">
                            <div class="flex flex-column">
                                <div>
                                    <span class="text-4xl text-800">{{ this.quantidadesFormatada[index] }}</span>
                                    <span class="text-xl text-700 ml-2">{{ this.porcentagem[index] || porcentagemCalculada[index] }}%</span>
                                </div>
                                <div class="flex align-items-center">
                                    <div :style="{ background: this.cores[index], width: '10px', height: '10px' }" />
                                    <div class="text-base text-700 ml-2">{{ exame }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderCard from '../../../relatorios-e-indicadores/components/HeaderCard.vue';
import { getClientBase } from '@/services/http';
import formatarNumero from '@/utils/FormatadorNumero';

export default {
    components: { HeaderCard },
    props: {
        customerIds: {
            type: Array,
            required: true
        },
        labelTotal: {
            type: String,
            default: 'Total'
        },
        grupoIds: {
            type: Array,
            required: true
        },
        customerBranchIds: {
            type: Array,
            required: true
        },
        titulo: {
            type: String,
            required: true
        },
        legendaPersonalizada: {
            type: Boolean,
            default: false
        },
        dadosPercentagem: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chartData: null,
            chartOptions: {
                cutout: '50%',
                plugins: {
                    legend: {
                        display: false
                    }
                }
            },
            dados: [],
            loading: false,
            path: '',
            periodicidade: null,
            dataInicio: null,
            dataFim: null
        };
    },
    computed: {
        quantidades() {
            return this.dados.map(({ quantidade }) => quantidade);
        },
        quantidadesFormatada() {
            return this.dados.map(({ quantidade }) => formatarNumero(quantidade));
        },
        porcentagemCalculada() {
            const total = this.dados.reduce((acc, item) => acc + item.quantidade, 0);
            const dadosComPorcentagem = this.dados.map((item) => ({
                ...item,
                porcentagem: (item.quantidade / total) * 100
            }));
            return dadosComPorcentagem.map(({ porcentagem }) => porcentagem?.toFixed(2));
        },
        porcentagem() {
            return this.dados.map(({ porcentagem }) => porcentagem);
        },
        labels() {
            return this.dados.map(({ nome }) => nome);
        },
        cores() {
            return this.dados.map(({ cor }) => cor);
        },
        dadosZerados() {
            return this.quantidades.every((item) => !item);
        },
        quantidadeTotal() {
            return formatarNumero(this.quantidades.reduce((total, quantidade) => Number(total) + Number(quantidade), 0));
        }
    },
    watch: {
        quantidade() {
            this.queryData(this.path);
        },
        periodicidade() {
            this.queryData(this.path);
        }
    },
    methods: {
        setChartData() {
            return {
                labels: this.labels,
                datasets: [
                    {
                        data: this.quantidades,
                        backgroundColor: this.cores,
                        hoverBackgroundColor: this.cores
                    }
                ]
            };
        },
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            if (this.path) {
                await this.queryData(this.path);
            }
        },
        async queryData(path) {
            this.loading = true;
            this.path = path;
            const { data } = await getClientBase().get(path, {
                params: {
                    filtros: JSON.stringify({
                        periodicidade: this.periodicidade,
                        dataInicio: this.dataInicio,
                        dataFim: this.dataFim,
                        customerIds: this.customerIds?.length ? this.customerIds : undefined,
                        grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                        customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                    })
                }
            });
            this.dados = data;
            this.chartData = this.setChartData();
            this.loading = false;
        }
    }
};
</script>
