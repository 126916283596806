<template>
    <div class="card p-5">
        <HeaderPanel :tittle="'Agendamentos'" :tittleCaminho="'Dashboard'" :loading="loading" nomeTelaDoManual="dashboard-agendamentos" />
        <div class="filter-cnt" :class="{ 'disabled': loading }">
            <div>
                <label for="txtFilterCliente" class="mb-1">Pesquisar por</label>
                <Dropdown
                    v-model="tipoFiltro"
                    :options="tipoFiltroOptions"
                    dataKey="id"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione..."
                    class="w-full"
                    @change="limparTipoNaoUtilizado"
                />
            </div>
            <div>
                <template v-if="tipoFiltro == 1" class="col-12 lg:col-4">
                    <label for="txtFilterCliente" class="mb-1">Cliente</label>
                    <MultiSelectCliente
                        v-model="customersSelectedIds"
                        dataKey="id"
                        optionLabel="name"
                        class="w-full"
                        placeholder="Selecione os clientes..."
                    />
                </template>
                <template v-if="tipoFiltro == 2" class="col-12 lg:col-4">
                    <label for="txtFilterCliente" class="mb-1">Grupo</label>
                    <MultiSelectGrupos v-model="groupSelectedIds" dataKey="id" class="w-full" placeholder="Selecione os grupos..." />
                </template>
                <template v-if="tipoFiltro == 3" class="col-12 lg:col-4">
                    <label for="txtFilterCliente" class="mb-1">Unidade</label>
                    <MultiSelectUnidadePorUsuario
                        v-model="customerBranchesSelectedIds"
                        dataKey="id"
                        class="w-full"
                        placeholder="Selecione as unidades..."
                    />
                </template>
            </div>
            <div>
                <div class="flex justify-content-end flex-row h-full">
                    <div class="flex align-items-end">
                        <Button
                            type="button"
                            label="Recarregar"
                            class="h-3rem"
                            @click="loadAllComponentes"
                            icon="pi pi-sync"
                            loadingIcon="pi pi-sync pi-spin"
                            :loading="loading"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="multi-panel-cnt">
                <MultiQuantityPanel ref="multiQuantityPanel" :customerIds="customerIds" :grupoIds="grupoIds" :customerBranchIds="customerBranchIds" />
            </div>
            <div style="margin: 20px 0">
                <ChartLinhaSimples
                    titulo="Quantidade de solicitações realizadas pelo cliente"
                    ref="quantidadeSolicitacaoCliente"
                    :customerIds="customerIds"
                    :grupoIds="grupoIds"
                    :customerBranchIds="customerBranchIds"
                >
                    <Filtro />
                </ChartLinhaSimples>
            </div>
            <div class="half-cnt">
                <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                    <QuantidadeDeSolicitacaoPorTipoExameChart
                        class="xl:col-6"
                        titulo="Quantidade de solicitação por tipo de exame"
                        ref="quantidadeSolicitacaoTipoExame"
                        legenda-personalizada
                        :customerIds="customerIds"
                        :grupoIds="grupoIds"
                        :customerBranchIds="customerBranchIds"
                        :labelTotal="'Total de exames'"
                    >
                        <Filtro />
                    </QuantidadeDeSolicitacaoPorTipoExameChart>
                </div>
                <DonutChart
                    class="xl:col"
                    titulo="ASO's enviados pelo funcionário"
                    ref="asoFuncionario"
                    legenda-personalizada
                    dados-percentagem
                    :customerIds="customerIds"
                    :grupoIds="grupoIds"
                    :customerBranchIds="customerBranchIds"
                    :labelTotal="`ASO's solicitados`"
                >
                    <Filtro />
                </DonutChart>
            </div>
            <div style="margin-bottom: 20px">
                <TablePanel
                    ref="agendamentoCidades"
                    titulo="Agendamentos por cidades"
                    :customerIds="customerIds"
                    :grupoIds="grupoIds"
                    :customerBranchIds="customerBranchIds"
                >
                    <Filtro />
                </TablePanel>
            </div>
            <div style="margin-bottom: 20px">
                <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                    <QuantidadeReagendadosChart
                        ref="QuantidadeReagendamento"
                        titulo="Reagendamento"
                        :customerIds="customerIds"
                        :grupoIds="grupoIds"
                        :customerBranchIds="customerBranchIds"
                    >
                        <Filtro />
                    </QuantidadeReagendadosChart>
                </div>
            </div>
            <div>
                <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                    <QuantidadeMotivoReagendamentosChart
                        ref="reagendamento"
                        titulo="Reagendamento"
                        :customerIds="customerIds"
                        :grupoIds="grupoIds"
                        :customerBranchIds="customerBranchIds"
                        :ocultarInfosFaltaPagamentoEkit="true"
                    >
                        <Filtro />
                    </QuantidadeMotivoReagendamentosChart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderPanel from '../../relatorios-e-indicadores/components/HeaderPanel.vue';
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente.vue';
import MultiSelectUnidadePorUsuario from '../../unidades/components/MultiSelectUnidadePorUsuario.vue';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import MultiQuantityPanel from '../components/MultiQuantityPanel/index.vue';
import Filtro from '../components/Filtro.vue';
import ChartLinhaSimples from '../components/ChartLinhaSimples.vue';
import DonutChart from '../components/DonutChart.vue';
import TablePanel from '../components/TablePanel.vue';
import QuantidadeReagendadosChart from '../../relatorios-e-indicadores/solicitacao-por-cliente/components/QuantidadeReagendadosChart.vue';
import QuantidadeMotivoReagendamentosChart from '../../relatorios-e-indicadores/solicitacao-por-cliente/components/QuantidadeMotivoReagendamentosChart.vue';
import QuantidadeDeSolicitacaoPorTipoExameChart from './components/QuantidadeDeSolicitacaoPorTipoExameChart.vue';
import MultiSelectGrupos from '../../grupos/components/MultiSelectGrupos.vue';
export default {
    components: {
        QuantidadeReagendadosChart,
        ChartLinhaSimples,
        Filtro,
        MultiQuantityPanel,
        HeaderPanel,
        MultiSelectCliente,
        MultiSelectGrupos,
        MultiSelectUnidadePorUsuario,
        DonutChart,
        TablePanel,
        QuantidadeMotivoReagendamentosChart,
        QuantidadeDeSolicitacaoPorTipoExameChart
    },
    data() {
        return {
            customerIds: [],
            customerBranchesSelectedIds: [],
            usersOptions: [],
            tipoFiltro: 1,
            groupSelectedIds: [],
            grupoIds: [],
            customerBranchIds: [],
            customersSelectedIds: [],
            tipoFiltroOptions: [
                { label: 'Clientes', value: 1 },
                { label: 'Grupos', value: 2 },
                { label: 'Unidades', value: 3 }
            ],
            loading: false
        };
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    async mounted() {
        await this.loadAllComponentes();
    },
    watch: {
        currentCustomer() {
            this.customerIds = this.currentCustomer;
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        },
        customersSelectedIds() {
            this.customerIds = this.customersSelectedIds.map((p) => p.id) ?? [];
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        },
        groupSelectedIds() {
            this.grupoIds = this.groupSelectedIds.map((p) => p.id) ?? [];
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        },
        customerBranchesSelectedIds() {
            this.customerBranchIds = this.customerBranchesSelectedIds.map((p) => p.id) ?? [];
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        }
    },
    methods: {
        limparTipoNaoUtilizado() {
            if (this.tipoFiltro == 1) {
                this.groupSelectedIds = [];
                this.customerBranchesSelectedIds = [];
            } else if (this.tipoFiltro == 2) {
                this.customersSelectedIds = [];
                this.customerBranchesSelectedIds = [];
            } else {
                this.groupSelectedIds = [];
                this.customersSelectedIds = [];
            }
        },
        async loadAllComponentes() {
            this.loading = true;
            try {
                await Promise.all([
                    this.$refs.multiQuantityPanel.queryData('/indicadores/agendamentos/por-clientes/agendamentos-por-tipo-de-status'),
                    this.$refs.quantidadeSolicitacaoCliente.queryData('/indicadores/agendamentos/por-clientes/solicitacoes-por-cliente'),
                    this.$refs.quantidadeSolicitacaoTipoExame.queryData('/indicadores/agendamentos/agendamentos-por-tipo-exames'),
                    this.$refs.asoFuncionario.queryData('/indicadores/agendamentos/asos-enviados-pelo-funcionario'),
                    this.$refs.agendamentoCidades.queryData('/indicadores/agendamentos/agendamentos-por-cidades'),
                    this.$refs.reagendamento.queryData('/indicadores/agendamentos/reagendamentos-por-clientes-e-funcionarios'),
                    this.$refs.QuantidadeReagendamento.queryData()
                ]);
                this.loading = false;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
                this.loading = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.filter-cnt {
    display: grid;
    grid-template-columns: calc(25% - 15px) calc(50% - 10px) calc(25% - 15px);
    gap: 20px;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
    }
}

.multi-panel-cnt {
    display: grid;
    grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    gap: 20px;
    margin: 20px 0;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
    }
}

.half-cnt {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
    margin: 20px 0;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
    }
}

::-webkit-scrollbar-track {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #f4f4f4;
    margin-left: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}
.disabled {
    pointer-events: none; 
    opacity: 0.5;
}
</style>
