<template>
    <div
        class="col-12 xl:col p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-auto md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
    >
        <HeaderCard :loading="loading" :tittle="titulo" @onLoad="load" />
        <div v-if="dados?.length">
            <DataTable :value="dados" removableSort lazy @sort="onSort($event)">
                <Column v-for="coluna in colunas" :key="coluna.chave" :field="coluna.chave" sortable :header="capitalize(coluna.field)">
                    <template #body="slotProps">
                        <span>{{ tratarDado(slotProps.data[coluna.chave].value) }}</span>
                    </template>
                </Column>
            </DataTable>
            <Paginator
                :rows="perPage"
                :totalRecords="total"
                @page="onPage($event)"
                template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
                currentPageReportTemplate="{currentPage} / {totalPages}"
            >
            </Paginator>
        </div>
        <MensagemSemDados v-else height-class="h-30rem" />
    </div>
</template>

<script>
import { getClientBase } from '@/services/http';
import { capitalize } from 'vue';
import { useFiltro } from '../composables/filtro';
import moment from 'moment-timezone';
import MensagemSemDados from './MensagemSemDados.vue';
import formatarNumero from '@/utils/FormatadorNumero';
import HeaderCard from '../../relatorios-e-indicadores/components/HeaderCard.vue';

export default {
    components: { MensagemSemDados, HeaderCard },
    props: {
        customerIds: {
            type: Array,
            required: true
        },
        grupoIds: {
            type: Array,
            required: true
        },
        customerBranchIds: {
            type: Array,
            required: true
        },
        titulo: { type: String, required: true },
        formatarValor: { type: Boolean, default: true }
    },
    setup() {
        return useFiltro();
    },
    data() {
        return {
            loading: false,
            dados: [],
            page: 1,
            total: 0,
            perPage: 10,
            path: '',
            sortField: null,
            sortOrder: null,
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
        };
    },
    computed: {
        colunas() {
            if (this.dados?.length) {
                const colunas = [];
                const item = this.dados[0];
                for (const chave in item) {
                    colunas.push({
                        ...item[chave],
                        chave
                    });
                }
                return colunas;
            }
            return [];
        }
    },
    watch: {
        quantidade() {
            this.queryData(this.path);
        },
        periodicidade() {
            this.queryData(this.path);
        }
    },
    methods: {
        capitalize,
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            if (this.path) {
                await this.queryData(this.path);
            }
        },
        async queryData(path, reload = true) {
            if (reload) {
                this.loading = true;
            }
            this.path = path;
            const { data } = await getClientBase().get(path, {
                params: {
                    filtros: JSON.stringify({
                        periodicidade: this.periodicidade,
                        dataInicio: this.dataInicio,
                        dataFim: this.dataFim,
                        customerIds: this.customerIds?.length ? this.customerIds : undefined,
                        grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                        customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                    }),
                    paginacao: JSON.stringify({
                        limit: this.perPage,
                        page: this.page,
                        sortField: this.sortField,
                        sortOrder: this.sortOrder
                    })
                }
            });
            this.dados = data.items;
            this.total = data.meta?.totalItems;
            this.totalPage = data.meta?.totalPages;
            this.loading = false;
        },
        formatDate(item) {
            return moment.tz(item, 'UTC').format('DD/MM/YYYY');
        },
        tratarDado(dado) {
            const tipoData = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dado);
            if (tipoData) {
                return this.formatDate(dado);
            }
            if (this.formatarValor && !isNaN(+dado)) {
                return formatarNumero(+dado);
            }
            return dado;
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.perPage = event.rows;
            await this.queryData(this.path);
        },
        async onSort(event) {
            const { sortField, sortOrder } = event;
            this.sortField = sortField;
            this.sortOrder = sortOrder;
            await this.queryData(this.path, false);
        }
    }
};
</script>
